<template>
  <div class="card card-custom card-stretch gutter-b bg-transparent">
    <!--begin::Header-->
    <div class="card-header border-0 py-3 bg-white mb-10 rounded-bottom">
      <b-card-title class="font-weight-bolder font-size-h1 py-2 pl-2"> {{ $tc("GENERAL.REDEEM_TOKEN", 1) | uppercase }}: {{ redeemedToken }}</b-card-title>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <transition name="fade-in-up" v-if="lastRedeemedToken === ''">
      <div class="card-body d-flex flex-column bg-white rounded">
        <p class="text-center font-weight-normal h5 pb-7 text-danger font-weight-bolder">*{{ $t("GENERAL.STARTS_LAT_TOKEN") }}</p>
        <div class="flex-grow-1">
          <div class="input-group input-group-xl">
            <div class="input-group-prepend">
              <span class="input-group-text" style="width: 60px">
                <i class="la flaticon2-tag ml-1 mt-1 h2"></i>
              </span>
            </div>
            <input
              ref="preferred_white_label"
              type="text"
              class="form-control form-control-lg text-muted"
              style="height: 60px"
              v-model="token"
              placeholder="LAT-"
            />
          </div>
        </div>
        <div class="pt-5">
          <button
            :disabled="!token"
            @click.prevent.stop="redeem"
            class="btn btn-success btn-shadow-hover font-weight-bolder py-3 btn-lg h3 mt-6 py-6 float-right"
          >
            <i class="fa fa-check"></i>
            {{ $t("GENERAL.REDEEM") }}
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade-in-up" v-if="lastRedeemedToken !== ''">
      <div>
        <b-card>
          <b-card-body class="">
            <b-row cols="1" class="d-flex col-span-1 justify-content-center">
              <b-col class="" style="max-width: 600px">
                <h2 class="text-center mb-10 mt-5 h1 text-dark">
                  {{ $t("GENERAL.CONGRATULATIONS") }}
                </h2>
                <p class="mb-10 mt-5 text-dark lead text-center" v-html="$t('GENERAL.REDEEMED_TOKEN_UNAUTHENTICATED')"></p>
                <h1 class="text-center mb-10 mt-5 text-dark font-weight-light">
                  {{ userEmail }}
                </h1>
                <p class="mb-10 mt-5 text-dark lead text-center">
                  {{ $t("GENERAL.REDEEMED_TOKEN_GREETINGS") }}
                </p>

                <p class="mb-10 mt-5 text-dark lead text-justify" v-if="user.force_reset_password">
                  {{ $t("GENERAL.REDEEMED_TOKEN_UNAUTHENTICATED4") }}
                </p>
                <div>
                  <div class="border rounded p-5">
                    <auth-forms :show-header="false" class="mt-10"></auth-forms>
                  </div>

                  <b-tabs content-class="mt-3" v-if="false">
                    <b-tab :title="$t('GENERAL.FIRST_ACCESS')" active>
                      <p class="mb-10 mt-5 text-dark lead text-justify">
                        {{ $t("GENERAL.REDEEMED_TOKEN_UNAUTHENTICATED2") }}
                      </p>
                    </b-tab>
                    <b-tab :title="$t('GENERAL.ALREADY_HAVE_ACCOUNT')"> </b-tab>
                  </b-tabs>
                </div>

                <p class="mb-10 mt-5 text-dark lead text-justify" v-if="false">
                  {{ $t("GENERAL.REDEEMED_TOKEN_UNAUTHENTICATED3") }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </transition>
    <!--end::Body-->
  </div>
</template>

<style>
.bg-translucent {
  background: rgba(255, 255, 255, 0.8);
}
a[role="tab"] {
  display: inline-block;
  font-weight: 600;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 16.275px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REDEEM_TOKEN_UNAUTHENTICATED } from "@/core/services/store/token.module";
import TheToaster from "@/core/services/the-toaster";
import { mapGetters } from "vuex";
import AuthForms from "@/view/pages/auth/components/AuthForms/AuthForms";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "redeem-token-unauthenticated",
  data() {
    return {
      user: {
        force_reset_password: false,
      },

      token: "",
      userEmail: "",
      redeemedToken: "",
    };
  },
  mixins: [uppercase],
  components: { AuthForms },
  methods: {
    redeem() {
      let self = this;
      if (!this.token.startsWith("LAT-")) {
        this.token = "LAT-" + this.token;
      }
      this.$store
        .dispatch("token/" + REDEEM_TOKEN_UNAUTHENTICATED, this.token)
        .then((response) => {
          Object.assign(this.user, response.data);
          self.userEmail = response.data.email;
          TheToaster.success(response.data.message, false);
        })
        .finally(() => {
          this.redeemedToken = this.token;
          this.token = "";
        });
    },
  },
  computed: {
    ...mapGetters({ layoutConfig: "layoutConfig", lastRedeemedToken: "token/lastRedeemedToken" }),
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
};
</script>
